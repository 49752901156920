'use strict'

import 'flatpickr/dist/flatpickr.css'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'
import { Dutch } from 'flatpickr/dist/l10n/nl'
import { english } from 'flatpickr/dist/l10n/default'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { Italian } from 'flatpickr/dist/l10n/it'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { CustomLocale, Locale } from 'flatpickr/dist/types/locale'
import { BaseOptions as CalendarOptions } from 'flatpickr/dist/types/options'
import { Gesture } from '@use-gesture/vanilla'
import { showTooltip, hideTooltip, setTooltipContent } from '@ui/Tooltip/component'
import type { Instance as CalendarInstance, DayElement } from 'flatpickr/dist/types/instance'

import { createXhr, isDesktop, isMobile, isTablet } from 'assets/core/js/common'
import SelectCustom from '@campings-group/design-system/src/design/objects/select-custom/twig/assets'
import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import MobileBottomPanel from '@ui/MobileBottomPanel/component'
import type { MobileBottomPanelType } from '@ui/MobileBottomPanel/component'

type PluginConfig<T = unknown> = T

type PluginsList = (typeof pluginsList)[number]

export type PluginsConfig<T = unknown> = (calendar: Calendar) => PluginConfig<T>

export interface CalendarPlugin {
  calendar: Calendar
}

export interface CalendarConfig {
  callbacks?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in (typeof callbacksAvailable)[number]]?: ((this: void, calendar: Calendar, ...args: any[]) => void)[]
  }
  numberOfMonths?: number
  currentLocale?: string[]
  closeOnDateChange?: boolean | (() => boolean)
  withNights?: boolean
  pluginsConfig?: Partial<Record<PluginsList, PluginsConfig>>
}

const pluginsList = ['flexibleDate', 'nightsSelection', 'refreshDates', 'rangeSelection'] as const

const callbacksAvailable = [
  'onChange',
  'onOpen',
  'onClose',
  'onDayOver',
  'onDayOut',
  'onMonthChange',
  'onClear',
  'onValueUpdate',
  'onDayCreate',
] as const

const languagesMapping: Record<string, CustomLocale | Locale> = {
  de: German,
  en: english,
  es: Spanish,
  fr: French,
  it: Italian,
  nl: Dutch,
}

export class Calendar {
  element!: HTMLElement
  input!: HTMLInputElement
  inputAlt!: HTMLInputElement
  config!: CalendarConfig
  isInit!: boolean
  isVisible!: boolean
  calendar!: CalendarInstance
  locale!: string
  mobilebottomPanel!: MobileBottomPanelType
  gesture!: Gesture
  plugins!: Partial<Record<PluginsList, CalendarPlugin>> | Record<string, unknown>

  constructor(element: string | HTMLElement, userConfig?: CalendarConfig) {
    this.element = this.resolveElement(element)

    this.config = userConfig ?? {}

    if (!this.config?.currentLocale) {
      return
    }

    if (!this.element.hasAttribute('data-field-id')) {
      return
    }

    const inputElement = document.querySelector<HTMLInputElement>(`#${this.element.getAttribute('data-field-id') as string}`)

    if (!inputElement) {
      return
    }

    this.input = inputElement

    this.isInit = false
    this.isVisible = false

    this.initConfig()
    this.initCalendar()
    this.initEvents()
    this.initMobilePanel()
    // keep plugins init as last
    this.initPlugins()
  }

  private resolveElement<T extends HTMLElement>(element: string | HTMLElement): T {
    let el: HTMLElement | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector<T>(element)
    }

    if (!el || typeof el === 'string') {
      throw new Error('Missing element.')
    }

    return el as T
  }

  getDate(): string {
    return this.input.value
  }

  setDate(date: string | string[]): void {
    this.calendar.setDate(date, false, 'Y-m-d')

    const inputDate = Array.isArray(date) ? date[0] : date

    if (inputDate) {
      this.setInputValue(inputDate)
    }
  }

  open(): void {
    if (this.isVisible) {
      return
    }

    this.element.removeAttribute('hidden')
    this.isVisible = true

    this.input.dispatchEvent(new CustomEvent('calendar.open'))

    if (this.config.callbacks?.onOpen) {
      this.config.callbacks.onOpen.forEach((cbFn) => {
        cbFn(this)
      })
    }
  }

  getMode(): string {
    return this.calendar.config.mode
  }

  close(): void {
    if (!this.isVisible) {
      return
    }

    this.element.setAttribute('hidden', 'hidden')
    this.isVisible = false

    this.input.dispatchEvent(new CustomEvent('calendar.close'))

    if (this.config.callbacks?.onClose) {
      this.config.callbacks.onClose.forEach((cbFn) => {
        cbFn(this)
      })
    }
  }

  private setInputValue(value: string | Date): void {
    this.input.value = value instanceof Date ? this.dateToYMD(value) : value
    this.inputAlt.value = this.formatDate(value instanceof Date ? value : new Date(value))

    this.config.callbacks?.onValueUpdate?.forEach((cbFn) => {
      cbFn(this)
    })
  }

  initCalendar(): void {
    const config = this.initOptions()

    const altInput = document.createElement('input')
    altInput.placeholder = this.input.placeholder ?? ''
    altInput.className = this.input.className ?? ''
    altInput.required = this.input.required ?? false
    altInput.readOnly = true
    altInput.tabIndex = 0
    altInput.type = 'text'
    altInput.id = `${this.input.id}-alt`

    this.inputAlt = this.input.parentNode?.insertBefore<HTMLInputElement>(altInput, this.input) as HTMLInputElement

    this.inputAlt.addEventListener('click', () => {
      this.open()
    })

    this.calendar = flatpickr(this.input, config)

    ElementPropertiesManager.addProperty(this.input, 'calendar', this)

    this.input.type = 'hidden'

    if (this.input.hasAttribute('data-panel-target')) {
      this.inputAlt.setAttribute('data-panel-target', this.input.getAttribute('data-panel-target') as string)
    }

    // update the label assodicated with the field to point to the alt input
    const label = document.querySelector(`label[for=${this.input.id}]`)

    if (label) {
      label.setAttribute('for', `${this.input.id}-alt`)
      this.inputAlt.setAttribute('id', `${this.input.id}-alt`)
    }

    this.locale = 'fr-FR'

    if (this.config.currentLocale) {
      this.locale = `${this.config.currentLocale[0] as string}-${this.config.currentLocale[1] as string}`
    }

    this.isInit = true
  }

  private initOptions(): Partial<CalendarOptions> {
    const config: Partial<CalendarOptions> = {
      // do not use flatpickr altInput as we want to have a custom format and the library does not allow it
      altInput: false,
      monthSelectorType: 'static',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      nextArrow: '',
      prevArrow: '',
      inline: true,
      showMonths: isMobile() || isTablet() ? 1 : 2,
      onMonthChange: () => {
        this.config.callbacks?.onMonthChange?.forEach((cbFn) => {
          cbFn(this)
        })
      },
      onValueUpdate: (selectedDates: Date[]) => {
        if (selectedDates[0] && this.locale) {
          this.setInputValue(selectedDates[0])
        }
      },
      onReady: () => {
        if (this.input.value) {
          this.inputAlt.value = this.formatDate(new Date(this.input.value))
        }
      },
      onDayCreate: (dates: Date[], dateStr: string, fp: CalendarInstance, dayElem: DayElement) => {
        if (fp.config.enable && !dayElem.classList.contains('disabled')) {
          dayElem.classList.add('enabled')
        }

        dayElem.setAttribute('data-date', this.dateToYMD(dayElem.dateObj))

        const span = document.createElement('span')
        span.innerText = dayElem.innerText
        dayElem.innerText = ''
        dayElem.appendChild(span)

        if (this.config.callbacks?.onDayCreate) {
          this.config.callbacks.onDayCreate.forEach((cbFn) => {
            cbFn(this, dateStr, dayElem)
          })
        }
      },
      onChange: (selectedDates: Date[], dateStr: string, fp: CalendarInstance) => {
        if (this.config.callbacks?.onChange && fp) {
          this.config.callbacks.onChange.forEach((cbFn) => {
            cbFn(this, dateStr, this.inputAlt.value)
          })
        }
      },
    }

    if (this.config.numberOfMonths) {
      config.showMonths = this.config.numberOfMonths
    }

    if (this.element.hasAttribute('data-select-all-days') === false) {
      // @ts-ignore fp_incr is defined by flatpickr
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      config.minDate = new Date().fp_incr(1)
    }

    const currentYear = new Date().getFullYear()

    if (this.input.hasAttribute('data-mindate')) {
      const [month, day] = this.input.getAttribute('data-mindate')?.split('-') as string[]
      config.minDate = `${currentYear}-${Number(month)}-${Number(day)}`
    }

    if (this.input.hasAttribute('data-maxdate')) {
      const [month, day] = this.input.getAttribute('data-maxdate')?.split('-') as string[]
      config.maxDate = `${currentYear}-${Number(month)}-${Number(day)}`
    }

    if (this.config.currentLocale) {
      config.locale = languagesMapping[this.config.currentLocale[0] as string] ?? 'fr'
    }

    const containerEl = this.element.querySelector<HTMLElement>(`#${this.element.id}-container`)

    if (containerEl) {
      config.appendTo = containerEl
    }

    return config
  }

  formatDate(date: Date): string {
    if (isNaN(date.getTime())) {
      return ''
    }

    return Intl.DateTimeFormat(this.locale).format(date)
  }

  private initConfig(): void {
    if (typeof this.config.closeOnDateChange === 'undefined') {
      this.config.closeOnDateChange = true
    }

    if (typeof this.config.callbacks === 'undefined') {
      this.config.callbacks = {}
    }

    callbacksAvailable.forEach((cb) => {
      if (typeof this.config.callbacks?.[cb] === 'undefined') {
        // @ts-ignore
        this.config.callbacks[cb] = []
      }
    })
  }

  private initMobilePanel(): void {
    const panelEl = this.element.closest<HTMLElement>('.mobile-bottompanel__calendar')

    if (!panelEl) {
      return
    }

    const panelId = panelEl.id

    this.mobilebottomPanel = MobileBottomPanel(`#${panelId}`)

    this.inputAlt.addEventListener('click', () => {
      this.mobilebottomPanel.open()
    })

    this.config.callbacks?.onClose?.push(() => {
      if (!isDesktop()) {
        this.mobilebottomPanel.close()
      }
    })

    const validateButtonEl = panelEl.querySelector<HTMLButtonElement>('.search-calendar__bottom-bar .o-button')

    if (validateButtonEl) {
      this.config.closeOnDateChange = () => !isMobile()

      this.config.callbacks?.onValueUpdate?.push(() => {
        if (validateButtonEl && ((this.getMode() === 'range' && this.calendar.selectedDates.length === 2) || this.getMode() !== 'range')) {
          validateButtonEl.removeAttribute('disabled')
          validateButtonEl.classList.remove('o-button--disabled')
        } else if (validateButtonEl) {
          validateButtonEl.setAttribute('disabled', 'disabled')
          validateButtonEl.classList.add('o-button--disabled')
        }
      })

      validateButtonEl.addEventListener('click', () => {
        this.mobilebottomPanel.close()
      })
    }
  }

  private initPlugins(): void {
    if (!this.config.pluginsConfig) {
      this.config.pluginsConfig = {}
    }

    if (this.element.hasAttribute('data-features')) {
      const activePlugins = this.element.getAttribute('data-features')?.split(',') as PluginsList[]

      // filter out the plugins that are not active from the config
      this.config.pluginsConfig = (Object.keys(this.config.pluginsConfig) as PluginsList[])
        .filter((key: PluginsList) => activePlugins.includes(key))
        .reduce((filteredObj: Partial<Record<PluginsList, PluginsConfig>>, key) => {
          filteredObj[key] = this.config.pluginsConfig ? this.config.pluginsConfig[key] : () => {}
          return filteredObj
        }, {})

      activePlugins.forEach((plugin) => {
        if (!this.config.pluginsConfig?.[plugin]) {
          // @ts-ignore
          this.config.pluginsConfig[plugin] = () => ({})
        }
      })
    }

    this.plugins = {}
    const pluginsKeys = Object.keys(this.config.pluginsConfig) as PluginsList[]

    pluginsKeys.forEach((key) => {
      const pluginConfigFn = this.config.pluginsConfig?.[key]

      if (!pluginConfigFn) {
        return
      }

      switch (key) {
        case 'flexibleDate':
          this.plugins.flexibleDate = new FlexibleDatePlugin(this, pluginConfigFn(this) as FlexibleDatePlugin['config'])
          break
        case 'nightsSelection':
          this.plugins.nightsSelection = new NightsSelectionPlugin(this, pluginConfigFn(this) as NightsSelectionPlugin['config'])
          break
        case 'refreshDates':
          this.plugins.refreshDates = new RefreshDatesPlugin(this, pluginConfigFn(this) as RefreshDatesPlugin['config'])
          break
        case 'rangeSelection':
          this.plugins.rangeSelection = new RangeSelectionPlugin(this, pluginConfigFn(this) as RangeSelectionPlugin['config'])
          break
      }
    })
  }

  private initEvents(): void {
    this.element.addEventListener('mouseover', (e) => {
      const target = e.target as HTMLElement

      if (target.classList.contains('flatpickr-day')) {
        let daysAfter = Array.from(this.calendar.calendarContainer.querySelectorAll<HTMLElement>('.flatpickr-day:not(.hidden)'))

        const indexOfElement = daysAfter.indexOf(target)
        if (indexOfElement !== -1) {
          daysAfter = daysAfter.splice(indexOfElement, daysAfter.length - 1)
        }

        daysAfter.shift()

        if (this.config.callbacks?.onDayOver) {
          this.config.callbacks.onDayOver?.forEach((cbFn) => {
            cbFn(this, target, daysAfter)
          })
        }
      }
    })

    this.element.addEventListener('mouseout', (e) => {
      const target = e.target as HTMLElement

      if (target.classList.contains('flatpickr-day')) {
        if (this.config.callbacks?.onDayOut) {
          this.config.callbacks.onDayOut?.forEach((cbFn) => {
            cbFn(this, target)
          })
        }
      }
    })

    this.gesture = new Gesture(
      this.element.querySelector<HTMLElement>('.flatpickr-rContainer') as HTMLElement,
      {
        onDragEnd: (state) => {
          if (!isMobile()) {
            return
          }

          if (state.velocity[0] < 0.5) {
            return
          }

          if (state.direction[0] === -1) {
            this.calendar.changeMonth(1)
          } else if (state.direction[0] === 1) {
            this.calendar.changeMonth(-1)
          }
        },
      },
      {
        drag: {
          axis: 'x',
        },
      }
    )

    this.element.addEventListener('clear', () => {
      this.calendar.clear()
    })

    const closeFn = (e: MouseEvent): void => {
      if (this.isVisible && e.target !== this.inputAlt && e.target !== this.input.parentNode && !this.element.contains(e.target as HTMLElement)) {
        this.close()
      }
    }

    this.config.callbacks?.onOpen?.push(() => {
      document.body.addEventListener('click', closeFn)
    })

    this.config.callbacks?.onClose?.push(() => {
      document.body.removeEventListener('click', closeFn)
    })

    this.config.callbacks?.onChange?.push(() => {
      if ((typeof this.config.closeOnDateChange === 'function' && this.config.closeOnDateChange() === false) || !this.config.closeOnDateChange) {
        return
      }

      if (this.getMode() === 'range' && this.calendar.selectedDates.length === 2) {
        this.close()
      } else if (this.getMode() !== 'range') {
        this.close()
      }
    })
  }

  dateToYMD(date: Date): string {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  clearDates(): void {
    this.calendar.clear()
    this.calendar?.set('enable', [() => true])
    this.input.value = ''
    this.inputAlt.value = ''

    if (this.config.callbacks?.onClear) {
      this.config.callbacks.onClear.forEach((cbFn) => {
        cbFn(this)
      })
    }
  }

  removeCalendar(): void {
    this.calendar?.destroy()
  }

  destroy(): void {
    this.calendar?.destroy()
    this.plugins = {}
  }
}

export class NightsSelectionPlugin implements CalendarPlugin {
  calendar!: Calendar
  selectElement!: SelectCustom
  otherSelectElement!: SelectCustom
  value!: number
  config!: PluginConfig<{
    onValueChange?: (value: number) => void
  }>

  constructor(calendar: Calendar, config = {}) {
    if (calendar.plugins.rangeSelection) {
      throw new Error('NightsSelectionPlugin cannot be used with RangeSelectionPlugin.')
    }

    this.calendar = calendar
    this.config = config

    this.initElements()
    this.initEvents()
    this.updateDate()

    this.calendar.formatDate = (date: Date): string => {
      if (isNaN(date.getTime())) {
        return ''
      }

      return this.formatDate(date)
    }
  }

  updateDate(): void {
    const date = this.calendar?.input.value

    if (date === '') {
      return
    }

    const text = this.formatDate(date)

    if (this.calendar.inputAlt) {
      this.calendar.inputAlt.value = text
    }
  }

  formatDate(date: Date | string): string {
    const startDate = new Date(date)
    const endDate = new Date(date)
    const fieldText = this.calendar.element.getAttribute('data-field-text')?.split('|') as string[]
    const nightsValue = this.value

    if (this.selectElement && this.selectElement.values[0]) {
      endDate.setDate(endDate.getDate() + parseInt(this.selectElement.values[0], 10))
    }

    if (this.calendar.dateToYMD(startDate) === this.calendar.dateToYMD(endDate)) {
      return Intl.DateTimeFormat(this.calendar.locale).format(startDate)
    }

    let datesText = `${startDate.toLocaleString(this.calendar.locale, { day: 'numeric' })} - ${endDate.toLocaleString(this.calendar.locale, {
      day: 'numeric',
    })} ${endDate.toLocaleString(this.calendar.locale, { month: 'short' })} ${endDate.toLocaleString(this.calendar.locale, { year: 'numeric' })}`

    if (startDate.getMonth() !== endDate.getMonth()) {
      datesText = `${startDate.toLocaleString(this.calendar.locale, { day: 'numeric' })} ${startDate.toLocaleString(this.calendar.locale, {
        month: 'short',
      })} - ${endDate.toLocaleString(this.calendar.locale, { day: 'numeric' })} ${endDate.toLocaleString(this.calendar.locale, {
        month: 'short',
      })} ${endDate.toLocaleString(this.calendar.locale, { year: 'numeric' })}`
    }

    let text = nightsValue > 1 ? (fieldText[1] as string) : (fieldText[0] as string)
    text = text.replace('%dates%', datesText)
    text = text.replace('%count%', nightsValue.toString())

    return text
  }

  private initElements(): void {
    const selectCustomElement = this.calendar.element.querySelector<HTMLDetailsElement>(`#${this.calendar.element.id}-nights-select`)
    const otherSelectCustomElement = this.calendar.element.querySelector<HTMLDetailsElement>(`#${this.calendar.element.id}-other-nights-select`)

    if (!selectCustomElement) {
      return
    }

    // retrieve the select inside each select custom so we can later bind the select custom instances to them
    const selectElement = selectCustomElement.querySelector<HTMLSelectElement>('summary select') as HTMLSelectElement
    const otherSelectElement = otherSelectCustomElement?.querySelector<HTMLSelectElement>('summary select') as HTMLSelectElement

    const nightsChipEl = selectCustomElement.closest('.search-calendar__nights-choice')
    const otherNightsChipEl = otherSelectCustomElement?.closest('.search-calendar__nights-others')

    if (!nightsChipEl) {
      return
    }

    if (!ElementPropertiesManager.hasProperty(selectElement, 'selectCustom')) {
      this.selectElement = new SelectCustom(selectCustomElement, {
        onValuesSet: (values: string[]): void => {
          const selectedValue = values[0]

          if (!selectedValue) {
            return
          }

          this.value = Number(selectedValue)

          // unset all preset chips
          this.calendar.element
            .querySelectorAll<HTMLElement>('.search-calendar__nights .search-calendar__nights-preset')
            .forEach((el) => el.removeAttribute('data-focused'))

          // get the chip matching the value of the select custom
          const el = this.calendar.element.querySelector(`.search-calendar__nights .search-calendar__nights-preset[data-nights="${this.value}"]`)

          el?.setAttribute('data-focused', 'true')

          if (values.length > 0) {
            nightsChipEl.setAttribute('data-focused', 'true')

            // in case the calendar wrapper is hidden, remove the attribute
            const calendarWrapper = this.calendar.element.querySelector(`#${this.calendar.element.id}-wrapper`) as HTMLElement
            calendarWrapper.removeAttribute('hidden')
          } else {
            nightsChipEl.removeAttribute('data-focused')
          }

          this.config.onValueChange && this.config.onValueChange(this.value)
          this.updateDate()
          // synchronize the value of this select with the one holding other values
          if (this.otherSelectElement && this.otherSelectElement.values[0] !== this.selectElement.values[0]) {
            this.otherSelectElement && this.otherSelectElement.setValues(values)
          }
        },
      })
      ElementPropertiesManager.addProperty<SelectCustom>(selectElement, 'selectCustom', this.selectElement)
    } else {
      this.selectElement = ElementPropertiesManager.getProperty<SelectCustom>(selectElement, 'selectCustom') as SelectCustom
    }

    if (otherNightsChipEl && otherSelectCustomElement) {
      if (!ElementPropertiesManager.hasProperty(otherSelectElement, 'selectCustom')) {
        this.otherSelectElement = new SelectCustom(otherSelectCustomElement, {
          onValuesSet: (values: string[]) => {
            if (values.length > 0) {
              // synchronize the value of this select with the one holding all values
              if (this.selectElement && values[0] !== this.selectElement.values[0]) {
                this.selectElement.setValues(values)
              }

              otherNightsChipEl.setAttribute('data-focused', 'true')
            } else {
              otherNightsChipEl.removeAttribute('data-focused')
            }
          },
        })
        ElementPropertiesManager.addProperty<SelectCustom>(otherSelectElement, 'selectCustom', this.otherSelectElement)
      } else {
        this.otherSelectElement = ElementPropertiesManager.getProperty<SelectCustom>(otherSelectElement, 'selectCustom') as SelectCustom
      }
    }
  }

  private initEvents(): void {
    this.calendar.element.querySelectorAll<HTMLElement>('.search-calendar__nights .search-calendar__nights-preset').forEach((el) => {
      el.addEventListener('click', () => {
        const value = el.getAttribute('data-nights') as string

        this.selectElement.setValues([value])
        this.otherSelectElement?.setValues([])
      })
    })

    this.calendar.config.callbacks?.onDayOver?.push((calendar, target, daysAfter: HTMLElement[]) => {
      const nights = parseInt(this.selectElement.values[0] as string, 10)

      daysAfter.slice(0, nights).forEach((el) => {
        el.setAttribute('data-highlighted', 'true')
      })
    })

    this.calendar.config.callbacks?.onDayOut?.push((calendar) => {
      calendar.calendar.calendarContainer.querySelectorAll('[data-highlighted]').forEach((dayEl) => {
        dayEl.removeAttribute('data-highlighted')
      })
    })
  }
}

export class FlexibleDatePlugin implements CalendarPlugin {
  calendar!: Calendar
  config!: PluginConfig<{
    onValueChange?: (value: number) => void
  }>

  constructor(calendar: Calendar, config = {}) {
    this.calendar = calendar
    this.config = config

    this.initEvents()
  }

  private initEvents(): void {
    this.calendar.element.querySelectorAll('.search-calendar__flexible-dates button[aria-controls]').forEach((el) => {
      el.addEventListener('click', () => {
        if (el.hasAttribute('data-focused')) {
          return
        }

        const clickedButtonEl = this.calendar.element.querySelector('.search-calendar__flexible-dates button[aria-controls].dca-chip[data-focused]')

        if (clickedButtonEl) {
          clickedButtonEl.removeAttribute('data-focused')
        }

        el.setAttribute('data-focused', 'true')

        this.config.onValueChange && this.config.onValueChange(Number(el.getAttribute('data-value') as string))
      })
    })
  }
}

interface RefreshDatesAjaxData {
  startDate: string
  productId: string
  nights: string
  numberOfMonths: number
}

export class RefreshDatesPlugin implements CalendarPlugin {
  calendar!: Calendar
  isPluginInit!: boolean
  currentXhrRequest!: XMLHttpRequest | null
  loaderEl!: HTMLElement | null
  availableDates!: string[]
  config!: PluginConfig<{
    ajaxUrl?: string
    ajaxData?: RefreshDatesAjaxData | (() => RefreshDatesAjaxData)
    firstDate?: string
    refreshOnMonthChange?: boolean
  }>

  constructor(calendar: Calendar, config = {}) {
    this.calendar = calendar
    this.config = config
    this.currentXhrRequest = null
    this.isPluginInit = false
    this.loaderEl = document.getElementById(`${this.calendar.element.id}-dates-loader`)
    this.availableDates = []

    if (typeof this.config.refreshOnMonthChange === 'undefined') {
      this.config.refreshOnMonthChange = true
    }

    this.initEvents()
  }

  private initEvents(): void {
    this.calendar.config.callbacks?.onOpen?.push(() => {
      // in case the user closed the calendar with only the start date active, don't refresh the available dates
      if (this.calendar.getMode() !== 'range' || (this.calendar.getMode() === 'range' && this.calendar.calendar.selectedDates.length !== 1)) {
        this.updateAvailableDates()
      }
    })

    this.calendar.config.callbacks?.onMonthChange?.push(() => {
      if (this.config.refreshOnMonthChange) {
        this.updateAvailableDates()
      }
    })

    this.calendar.config.callbacks?.onClear?.push(() => {
      this.isPluginInit = false
      this.updateAvailableDates()
    })
  }

  enableRefresh(): void {
    this.config.refreshOnMonthChange = true
  }

  disableRefresh(): void {
    this.config.refreshOnMonthChange = false
  }

  updateAvailableDates(): void {
    if (!this.calendar.isVisible) {
      return
    }

    // handle a rare case when the calendar is destroyed but a call to this method is still started
    if (!this.calendar.calendar || !this.calendar.calendar.config || !this.config.ajaxUrl || !this.config.ajaxData) {
      return
    }

    const firstDate =
      this.config.firstDate && !this.isPluginInit
        ? this.config.firstDate
        : `${this.calendar.calendar.currentYear}-${('0' + (this.calendar.calendar.currentMonth + 1)).slice(-2)}-01`

    const ajaxData: RefreshDatesAjaxData = typeof this.config.ajaxData === 'function' ? this.config.ajaxData() : this.config.ajaxData

    if (!ajaxData) {
      return
    }

    ajaxData.startDate = firstDate
    const givenDate = new Date(firstDate)
    const today = new Date()

    // if we have at least one month between the first date and today then use the first day of the previous month as the starting date
    const yearDifference = givenDate.getFullYear() - today.getFullYear()
    const monthDifference = yearDifference * 12 + givenDate.getMonth() - today.getMonth()

    if (monthDifference >= 1) {
      givenDate.setDate(1)
      givenDate.setMonth(givenDate.getMonth() - 1)
      ajaxData.startDate = this.calendar.dateToYMD(givenDate)
      ajaxData.numberOfMonths = ajaxData.numberOfMonths + 1
    }

    this.loaderEl?.removeAttribute('hidden')

    if (!this.isPluginInit && this.calendar.calendar.config) {
      this.calendar.calendar.jumpToDate(this.config.firstDate)
    }

    if (this.currentXhrRequest && this.currentXhrRequest.readyState < XMLHttpRequest.DONE) {
      this.currentXhrRequest.abort()
    }

    this.currentXhrRequest = createXhr('POST', this.config.ajaxUrl)
    this.currentXhrRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')

    this.currentXhrRequest.onreadystatechange = () => {
      try {
        if (this.currentXhrRequest && this.currentXhrRequest.readyState === XMLHttpRequest.DONE && this.currentXhrRequest.status === 200) {
          let dates = JSON.parse<Array<unknown>>(this.currentXhrRequest.response as string)
          dates = Array.isArray(dates) ? dates : []

          if (!this.isPluginInit) {
            this.isPluginInit = true
          }

          this.availableDates = dates.map((date) => date as string)

          if (this.calendar.calendar.selectedDates && this.calendar.calendar.selectedDates.length === 2) {
            dates.push({
              from: this.calendar.dateToYMD(this.calendar.calendar.selectedDates[0] as Date),
              to: this.calendar.dateToYMD(this.calendar.calendar.selectedDates[1] as Date),
            })
          }

          this.calendar.calendar?.set('enable', dates)

          if (dates.length === 0) {
            this.calendar.calendar?.set('disable', [() => true])
          }

          this.loaderEl?.setAttribute('hidden', 'hidden')
        }
      } catch (e) {
        this.loaderEl?.setAttribute('hidden', 'hidden')
        this.calendar.calendar?.set('disable', [() => true])
      }
    }

    this.currentXhrRequest.send(
      Object.keys(ajaxData)
        .map((key) => `${key}=${ajaxData[key as keyof RefreshDatesAjaxData]}`)
        .join('&')
    )
  }
}

interface RangeSelectionAjaxData {
  startDate: string
  productId: string
  nights: string
}

export class RangeSelectionPlugin implements CalendarPlugin {
  calendar!: Calendar
  currentXhrRequest!: XMLHttpRequest | null
  shouldRefreshDates!: boolean
  currentNights!: number | null
  minNights!: number | null
  maxNights!: number | null
  allowedDaysRange!: number[]
  availableDatesBeforeStartDate!: Date[]
  loaderEl!: HTMLElement | null
  config!: PluginConfig<{
    ajaxUrl?: string | null
    ajaxData?: RangeSelectionAjaxData | (() => RangeSelectionAjaxData)
    onValueChange?: (nights?: number) => void
  }>

  constructor(calendar: Calendar, config = {}) {
    if (calendar.plugins.nightsSelection) {
      throw new Error('RangeSelectionPlugin cannot be used with NightsSelectionPlugin.')
    }

    this.calendar = calendar
    this.config = config
    this.currentXhrRequest = null
    this.shouldRefreshDates = 'ajaxUrl' in this.config && this.config.ajaxUrl !== null

    this.currentNights = this.calendar.element.hasAttribute('data-range-current-nights')
      ? Number(this.calendar.element.getAttribute('data-range-current-nights'))
      : null
    this.minNights = this.calendar.element.hasAttribute('data-range-min-nights')
      ? Number(this.calendar.element.getAttribute('data-range-min-nights'))
      : null
    this.maxNights = this.calendar.element.hasAttribute('data-range-max-nights')
      ? Number(this.calendar.element.getAttribute('data-range-max-nights'))
      : null
    this.allowedDaysRange = this.calendar.element.hasAttribute('data-range-allowed-days-range')
      ? (this.calendar.element.getAttribute('data-range-allowed-days-range') as string).split(',').map(Number)
      : [1]
    this.availableDatesBeforeStartDate = []

    this.calendar.calendar.set('mode', 'range')

    this.calendar.formatDate = (date: Date): string => {
      if (isNaN(date.getTime())) {
        return ''
      }

      return this.formatDate(date)
    }

    this.loaderEl = document.getElementById(`${this.calendar.element.id}-dates-loader`)

    this.setEndDateFromNights()
    this.initEvents()
    this.initTooltips()
    this.initClearButton()
  }

  formatDate(date: Date | string): string {
    const startDate = new Date(date)
    let endDate = new Date(date)
    const fieldText = this.calendar.element.getAttribute('data-field-text')?.split('|') as string[]

    if (this.calendar.calendar.selectedDates && this.calendar.calendar.selectedDates.length === 2) {
      endDate = this.calendar.calendar.selectedDates[1] as Date
    }

    const nightsValue = this.getNights(startDate, endDate)

    if (this.calendar.dateToYMD(startDate) === this.calendar.dateToYMD(endDate)) {
      return Intl.DateTimeFormat(this.calendar.locale).format(startDate)
    }

    let datesText = `${startDate.toLocaleString(this.calendar.locale, { day: 'numeric' })} - ${endDate.toLocaleString(this.calendar.locale, {
      day: 'numeric',
    })} ${endDate.toLocaleString(this.calendar.locale, { month: 'short' })} ${endDate.toLocaleString(this.calendar.locale, { year: 'numeric' })}`

    if (startDate.getMonth() !== endDate.getMonth()) {
      datesText = `${startDate.toLocaleString(this.calendar.locale, { day: 'numeric' })} ${startDate.toLocaleString(this.calendar.locale, {
        month: 'short',
      })} - ${endDate.toLocaleString(this.calendar.locale, { day: 'numeric' })} ${endDate.toLocaleString(this.calendar.locale, {
        month: 'short',
      })} ${endDate.toLocaleString(this.calendar.locale, { year: 'numeric' })}`
    }

    let text = nightsValue > 1 ? (fieldText[1] as string) : (fieldText[0] as string)
    text = text.replace('%dates%', datesText)
    text = text.replace('%count%', nightsValue.toString())

    return text
  }

  private setEndDateFromNights(): void {
    const startDate = this.calendar.calendar.selectedDates ? this.calendar.calendar.selectedDates[0] : null

    if (!startDate || !this.currentNights) {
      return
    }

    const endDate = new Date(startDate).setDate(startDate.getDate() + this.currentNights)
    this.calendar.setDate([this.calendar.dateToYMD(startDate), this.calendar.dateToYMD(new Date(endDate))])
  }

  private initEvents(): void {
    this.calendar.config.callbacks?.onChange?.push(() => {
      if (this.shouldRefreshDates) {
        this.updateAvailableDates()
      }

      if (this.calendar.calendar.selectedDates && this.calendar.calendar.selectedDates[0]) {
        // disable refresh for the RefreshDatesPlugin as it can conflict with the refresh of the range plugin when changing month
        this.calendar.plugins.refreshDates && (this.calendar.plugins.refreshDates as RefreshDatesPlugin).disableRefresh()

        this.saveAvailableDatesBeforeStartDate(this.calendar.calendar.selectedDates[0])

        // disable dates after selecting first date if we don't query for unavailable dates
        !this.config.ajaxUrl && this.setUnavailableDates(this.calendar.calendar.selectedDates[0])

        // update the status of the current selected date so the tooltip can be displayed
        this.minNights && this.updateDatesStatus([this.calendar.dateToYMD(this.calendar.calendar.selectedDates[0])], 'no-minimum-nights', false)
      }

      // when we have 2 selected dates, calculate the nights and call the onValueChange callback
      if (this.calendar.calendar.selectedDates && this.calendar.calendar.selectedDates.length === 2) {
        this.calendar.plugins.refreshDates && (this.calendar.plugins.refreshDates as RefreshDatesPlugin).enableRefresh()

        this.currentNights = this.getNights(this.calendar.calendar.selectedDates[0] as Date, this.calendar.calendar.selectedDates[1] as Date)
        this.config.onValueChange && this.config.onValueChange(this.currentNights)
      }
    })

    this.calendar.config.callbacks?.onOpen?.push(() => {
      // if we don't have to refresh the available dates and the user selected 2 dates, enable back all dates
      if (!this.shouldRefreshDates && this.calendar.calendar.selectedDates && this.calendar.calendar.selectedDates.length === 2) {
        this.calendar.calendar?.set('enable', [() => true])
      }

      // in case the user selected a start date (and possibly changed months) but decided to close the calendar and open it again
      if (this.calendar.calendar.selectedDates.length === 1 && this.calendar.calendar.selectedDates[0]) {
        this.calendar.calendar.jumpToDate(this.calendar.calendar.selectedDates[0])

        if (!this.shouldRefreshDates) {
          this.setUnavailableDates(this.calendar.calendar.selectedDates[0])
        }

        if (this.shouldRefreshDates) {
          this.updateAvailableDates()
        }
      }
    })

    this.calendar.config.callbacks?.onMonthChange?.push(() => {
      if (this.shouldRefreshDates) {
        this.updateAvailableDates()
      }

      // if we don't have to refresh the available dates and the user selected 1 date, set again all unavailable dates
      if (!this.shouldRefreshDates && this.calendar.calendar.selectedDates.length === 1 && this.calendar.calendar.selectedDates[0]) {
        this.setUnavailableDates(this.calendar.calendar.selectedDates[0])
      }
    })

    this.calendar.config.callbacks?.onClear?.push(() => {
      this.calendar.plugins.refreshDates && (this.calendar.plugins.refreshDates as RefreshDatesPlugin).enableRefresh()

      this.config.onValueChange && this.config.onValueChange()
    })

    this.calendar.config.callbacks?.onDayCreate?.push((calendar, dateStr, dayEl: DayElement) => {
      // if the date is before the first selected dates, add an attribute so we can reset the range styles with css and allow the user to set another start date
      if (
        this.calendar.calendar.selectedDates &&
        this.calendar.calendar.selectedDates[0] &&
        dayEl.dateObj < this.calendar.calendar.selectedDates[0]
      ) {
        dayEl.setAttribute('data-no-range', 'true')
      }

      // when a user click on a date before the first selected one, reset the calendar so the new date can be set as the start date
      dayEl.addEventListener(
        'click',
        () => {
          if (!dayEl.classList.contains('flatpickr-disabled') && dayEl.hasAttribute('data-no-range')) {
            this.calendar.calendar.clear(true, false)
          }
        },
        { once: true }
      )

      // for dates that are beyond the second selected dates, add a class so we can apply specific styles to them
      if (
        this.calendar.calendar.selectedDates &&
        this.calendar.calendar.selectedDates[0] &&
        !this.calendar.calendar.selectedDates[1] &&
        dayEl.dateObj > this.calendar.calendar.selectedDates[0]
      ) {
        dayEl?.classList.add('unselectable')
      }
    })

    this.calendar.config.callbacks?.onDayOut?.push((calendar, target: HTMLElement) => {
      if (this.calendar.calendar.selectedDates && this.calendar.calendar.selectedDates.length === 2) {
        return
      }

      if (!target.classList.contains('flatpickr-disabled') && (target.classList.contains('inRange') || target.classList.contains('endRange'))) {
        this.calendar.element.setAttribute('data-disable-range', 'true')
      }

      if (target.classList.contains('flatpickr-disabled') && this.calendar.element.hasAttribute('data-disable-range')) {
        this.calendar.element.removeAttribute('data-disable-range')
      }
    })

    this.calendar.config.callbacks?.onDayOver?.push((calendar, target: DayElement) => {
      if (this.calendar.calendar.selectedDates && this.calendar.calendar.selectedDates.length > 1) {
        return
      }

      // to be able to click on previous dates that were marked as "not allowed" by flatpickr we need to remove the class when the user hover a date
      if (this.shouldRefreshDates) {
        const dates = (this.calendar.plugins.refreshDates as RefreshDatesPlugin).availableDates

        if (dates.includes(this.calendar.dateToYMD(target.dateObj))) {
          target.classList.remove('notAllowed')
        }
      }

      if (!target.classList.contains('flatpickr-disabled') && (target.classList.contains('inRange') || target.classList.contains('endRange'))) {
        this.calendar.element.removeAttribute('data-disable-range')
      }

      if (target.classList.contains('flatpickr-disabled') && !this.calendar.element.hasAttribute('data-disable-range')) {
        this.calendar.element.setAttribute('data-disable-range', 'true')
      }
    })
  }

  private initClearButton(): void {
    const clearDatesEls = this.calendar.element.querySelectorAll<HTMLButtonElement>('.search-calendar__clear-dates')

    if (!clearDatesEls) {
      return
    }

    clearDatesEls.forEach((el) => {
      el.addEventListener('click', () => {
        this.calendar.clearDates()
        this.availableDatesBeforeStartDate = []
      })

      if (this.calendar.calendar.selectedDates && this.calendar.calendar.selectedDates[0]) {
        el.removeAttribute('disabled')
      }
    })

    this.calendar.config.callbacks?.onValueUpdate?.push(() => {
      if (this.calendar.calendar.selectedDates[0]) {
        clearDatesEls.forEach((el) => el.removeAttribute('disabled'))
      }
    })

    this.calendar.config.callbacks?.onClear?.push(() => {
      clearDatesEls.forEach((el) => el.setAttribute('disabled', 'disabled'))
    })
  }

  updateAvailableDates(): void {
    if (!this.calendar.isVisible) {
      return
    }

    // handle a rare case when the calendar is destroyed but a call to this method is still started
    if (!this.calendar.calendar || !this.calendar.calendar.config || !this.config.ajaxUrl || !this.config.ajaxData) {
      return
    }

    // don't get dates if we don't have a start date, also don't get dates if we already have a end date
    if (!this.calendar.calendar.selectedDates || !this.calendar.calendar.selectedDates[0] || this.calendar.calendar.selectedDates[1]) {
      return
    }

    const ajaxData: RangeSelectionAjaxData = typeof this.config.ajaxData === 'function' ? this.config.ajaxData() : this.config.ajaxData

    if (!ajaxData) {
      return
    }

    const startDate = this.calendar.calendar.selectedDates[0]
    ajaxData.startDate = this.calendar.dateToYMD(startDate)

    this.loaderEl?.removeAttribute('hidden')

    if (this.currentXhrRequest && this.currentXhrRequest.readyState < XMLHttpRequest.DONE) {
      this.currentXhrRequest.abort()
    }

    this.currentXhrRequest = createXhr('POST', this.config.ajaxUrl)
    this.currentXhrRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')

    this.calendar.calendar?.set('enable', [startDate])

    this.currentXhrRequest.onreadystatechange = () => {
      try {
        if (this.currentXhrRequest && this.currentXhrRequest.readyState === XMLHttpRequest.DONE && this.currentXhrRequest.status === 200) {
          let dates = JSON.parse<Array<string>>(this.currentXhrRequest.response as string)
          dates = Array.isArray(dates) ? dates : []

          this.calendar.calendar?.set('enable', [
            ...(this.calendar.plugins.refreshDates as RefreshDatesPlugin).availableDates.filter((d) => new Date(d) < startDate),
            {
              from: startDate,
              to: dates[dates.length - 1],
            },
          ])

          this.setUnavailableEndDates(dates, ajaxData.startDate, dates[dates.length - 1] as string)
        }

        this.loaderEl?.setAttribute('hidden', 'hidden')
      } catch (e) {
        this.loaderEl?.setAttribute('hidden', 'hidden')
        this.calendar.clearDates()
      }
    }

    this.currentXhrRequest.send(
      Object.keys(ajaxData)
        .map((key) => `${key}=${ajaxData[key as keyof RangeSelectionAjaxData]}`)
        .join('&')
    )
  }

  private getNights(startDate: Date, endDate: Date): number {
    const diffInMilliseconds = Math.abs(new Date(endDate.setHours(23, 59, 59, 999)).getTime() - new Date(startDate.setHours(0, 0, 0, 0)).getTime())
    const millisecondsPerDay = 1000 * 60 * 60 * 24

    return Math.floor(diffInMilliseconds / millisecondsPerDay)
  }

  private saveAvailableDatesBeforeStartDate(startDate: Date): void {
    const dates = this.shouldRefreshDates
      ? (this.calendar.plugins.refreshDates as RefreshDatesPlugin).availableDates.map(
          (date) => document.querySelector<DayElement>(`.flatpickr-day[data-date="${date}"]`) as DayElement
        )
      : Array.from(this.calendar.element.querySelectorAll<DayElement>('.flatpickr-day:not(.hidden)'))

    this.availableDatesBeforeStartDate = dates.filter((el: DayElement) => el !== null && el.dateObj < startDate).map((el: DayElement) => el.dateObj)
  }

  private setUnavailableEndDates(dates: string[], startDate: string, endDate: string): void {
    if (!startDate || !endDate) {
      return
    }

    const start = new Date(startDate)
    const end = new Date(endDate)

    dates.unshift(startDate)

    const unavailableDates: string[] = []
    const currentDate = new Date(start)
    while (currentDate.toISOString() <= end.toISOString()) {
      unavailableDates.push(this.calendar.dateToYMD(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    this.updateDatesStatus(unavailableDates.filter((date) => !dates.includes(date)) ?? [], 'unavailable-date')
  }

  private setUnavailableDates(startDate: Date): void {
    const unavailableDates: string[] = []

    if (!this.minNights || !this.maxNights) {
      return
    }

    const endDate = new Date(new Date(startDate).setDate(startDate.getDate() + this.maxNights))
    const currentDate = new Date(startDate)
    const allowedDates: string[] = []

    this.allowedDaysRange.forEach((d) => {
      allowedDates.push(this.calendar.dateToYMD(new Date(new Date(startDate).setDate(startDate.getDate() + d))))
    })

    // eslint-disable-next-line no-unmodified-loop-condition
    while (currentDate <= endDate) {
      currentDate.setDate(currentDate.getDate() + 1)
      const newDate = this.calendar.dateToYMD(currentDate)
      if (!allowedDates.includes(newDate)) {
        unavailableDates.push(newDate)
      }
    }

    this.calendar.calendar?.set('enable', [
      ...this.availableDatesBeforeStartDate,
      {
        from: startDate,
        to: endDate,
      },
    ])

    this.updateDatesStatus(unavailableDates, 'no-minimum-nights')
  }

  private updateDatesStatus(dates: string[], status: 'unavailable-date' | 'no-minimum-nights', disableDate = true): void {
    dates.forEach((date) => {
      const dateEl = this.calendar.calendar.calendarContainer.querySelector<HTMLElement>(`.flatpickr-day:not(.hidden)[data-date="${date}"]`)

      if (!dateEl) {
        return
      }

      disableDate && dateEl.classList.add('flatpickr-disabled')
      dateEl.classList.add(status)
    })
  }

  private initTooltips(): void {
    const tooltipEl = this.calendar.element.querySelector<HTMLElement>('.calendar-date-tooltip')

    if (!tooltipEl) {
      return
    }

    const tooltipContentEl = tooltipEl.querySelector<HTMLElement>('.content')

    if (!tooltipContentEl) {
      return
    }

    const showCalendarTooltip = (e: TouchEvent | MouseEvent): void => {
      const target = e.target as HTMLElement
      const targetDate = new Date(target.getAttribute('data-date') as string)

      if (!this.calendar.calendar.selectedDates[0]) {
        return
      }

      const nights = this.getNights(this.calendar.calendar.selectedDates[0], targetDate)

      if (
        this.minNights &&
        this.maxNights &&
        targetDate >= this.calendar.calendar.selectedDates[0] &&
        nights <= this.maxNights &&
        target.classList.contains('no-minimum-nights')
      ) {
        const message = tooltipEl.getAttribute('data-range-min-nights-message') as string
        setTooltipContent(tooltipEl, message)
        tooltipEl && showTooltip(target, tooltipEl, 0)
      }

      if (targetDate > this.calendar.calendar.selectedDates[0] && target.classList.contains('unavailable-date')) {
        const message = tooltipEl.getAttribute('data-unavailable-message') as string
        setTooltipContent(tooltipEl, message)
        tooltipEl && showTooltip(target, tooltipEl, 0)
      }

      if (
        this.maxNights &&
        targetDate > this.calendar.calendar.selectedDates[0] &&
        nights > this.maxNights &&
        target.classList.contains('flatpickr-disabled')
      ) {
        const message = tooltipEl.getAttribute('data-range-outofrange-message') as string
        setTooltipContent(tooltipEl, message)
        tooltipEl && showTooltip(target, tooltipEl, 0)
      }
    }

    const hideCalendarTooltip = (e: TouchEvent | MouseEvent): void => {
      const target = e.target as HTMLElement

      tooltipEl && !tooltipEl.hasAttribute('hidden') && hideTooltip(e, target, tooltipEl)
    }

    this.calendar.element.addEventListener('mouseover', (e) => {
      if (!isDesktop()) {
        return
      }

      showCalendarTooltip(e)
    })

    this.calendar.element.addEventListener('touchstart', (e) => {
      hideCalendarTooltip(e)

      showCalendarTooltip(e)
    })

    this.calendar.element.addEventListener('mouseout', hideCalendarTooltip)
  }
}

export default (element: string | HTMLElement, userConfig?: CalendarConfig): Calendar => {
  return new Calendar(element, userConfig)
}
